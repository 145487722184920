<template>
  <div class="modal is-active" style="justify-content: normal;">
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_0_pick_word.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_1_draw_volcano.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_2_guess_volcano.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_3_draw_volcano.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_4_guess_party_confetti.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_5_draw_party_confetti.low.mp4')"/>
    <link rel="prefetch" as="video" type="video/mp4" :href="require('@/resources/videos/tuto_v2/step_6_view_drawings.low.mp4')"/>

    <div class="modal-background" @click="close"></div>
    <div class="modal-card" style="margin-top: 40px;">
      <header class="modal-card-head">
        <p class="modal-card-title" style="font-family: cabin_sketch_bold;">{{ $t("sketchy.global.tutoriel") }}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>

      <section class="modal-card-body frosted-background-static" style="color:black">

        <div v-if="true">
          <BoutonGauche
            :style="{
              'opacity': page <= 1 ? '0.2' : undefined,
              'cursor': page <= 1 ? 'default' : undefined,
            }"
            @click="previous"
            class="arrow is-pulled-left"
          />
          <BoutonDroite
            :style="{
              'opacity': page >= 7 ? '0.2' : undefined ,
              'cursor': page >= 7 ? 'default' : undefined,
            }"
            @click="next"
            class="arrow is-pulled-right"
          />

          <div v-if="page===1">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page1') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_0_pick_word.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

          <div v-if="page===2">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page2') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_1_draw_volcano.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>


          <div v-if="page===3">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page3') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_2_guess_volcano.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>


          <div v-if="page===4">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page4') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_3_draw_volcano.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

          <div v-if="page===5">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page5') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_4_guess_party_confetti.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div v-if="page===6">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page6') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_5_draw_party_confetti.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div v-if="page===7">
            <h1 class="page_indicator">{{ page }}/7</h1>
            <h1 class="has-text-centered tutoText">{{ $t('sketchy.tutoriel.page7') }}</h1>
            <div class="is-clearfix"></div>
            <video width="100%" height="auto" autoplay="autoplay" muted webkit-playsinline webkitplaysinline playsinline loop>
              <source src="@/resources/videos/tuto_v2/step_6_view_drawings.low.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

        </div>
        <div v-else>
          <div v-if=" $locale === 'fr' ">
            <TutoFr style="width: 70%;margin-left: 15%;"/>
          </div>
          <div v-else>
            <TutoEn style="width: 70%;margin-left: 15%;"/>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.tutoText {
  font-family: cabin_sketch_bold;
  font-size: 28px;
  @include mobile {
    font-size: 20px;
  }
}

.page_indicator {
  width: 100%;
  text-align: center;
  font-family: cabin_sketch_regular;
  color: #666;
  font-size: 18px;
  margin-top: -16px;
  margin-bottom: -5px;
}

.arrow {
  height: 50px;
  width: auto;
  margin: 10px;
  @include mobile {
    height: 40px;
    margin: 5px;
  }
}
</style>

<script>
export default {
  name: 'ModalTuto',

  data() {
    return {
      page: 1,

      dernierePageLue: false,
    }
  },
  methods: {
    close() {
      this.$emit('close');
      if (!this.dernierePageLue) {
        this._matomo_trackGoal(this.MTM_GOAL_TUTO_UNFINISH);
      }
    },

    previous() {
      if (this.page > 1) {
        const newPage = this.page - 1;
        this.page = null;
        this.$nextTick(() => {
          this.page = newPage;
        });
      }
    },

    next() {
      if (this.page < 7) {
        const newPage = this.page + 1;
        this.page = null;
        this.$nextTick(() => {
          this.page = newPage;
        });

        if (newPage === 7 && !this.dernierePageLue) {
          this.dernierePageLue = true;
          this._matomo_trackGoal(this.MTM_GOAL_TUTO_FINISH);
        }
      }
    },
  },

  components: {
    TutoFr: () => import('@/resources/images/TutoFr'),
    TutoEn: () => import('@/resources/images/TutoEn'),
    BoutonDroite: () => import('../global/boutons/BoutonFlecheDroite'),
    BoutonGauche: () => import('../global/boutons/BoutonFlecheGauche'),
  }
}
</script>